import React, { Fragment } from 'react'

export const Footer = () => {
    return (
        <Fragment>
            <footer className='footerSection'>
                <div className='container-sm'>
                    <ul className='socialIcon'>
                        <li className='socialIconItem'>
                            <a href="https://www.facebook.com/profile.php?id=61552254993997" className='socialIconLink'  target="_blank" rel="noopener noreferrer"><i className="bi bi-facebook"></i></a>
                        </li>
                        <li className='socialIconItem'>
                            <a href="https://instagram.com/schoolcoastroad?igshid=OGQ5ZDc2ODk2ZA==" className='socialIconLink' target="_blank" rel="noopener noreferrer"><i className="bi bi-instagram"></i></a>
                        </li>
                        <li className='socialIconItem'>
                            <a href="https://www.youtube.com/" className='socialIconLink' target="_blank" rel="noopener noreferrer"><i className="bi bi-youtube"></i></a>
                        </li>
                        <li className='socialIconItem'>
                            <a href="https://api.whatsapp.com/send?phone=+17543190073&text=¡Hola!%20Quiero%20mas%20informacion%20sobre%20tramites%20de%20licencia" className='socialIconLink' target="_blank" rel="noopener noreferrer"><i className="bi bi-whatsapp"></i></a>
                        </li>
                    </ul>
                    <div className="footerInfo  pb-2">
                        <hr className='text-secondary'></hr>
                        <div className="row d-flex align-items-center justify-content-center">
                            <div className="col-xl-4 col-md-4 d-flex align-items-center justify-content-center mb-3">
                                <div className="single-cta">
                                    <i className="bi bi-telephone-fill"></i>
                                    <div className="cta-text">
                                        <h4>Call us</h4>
                                        <span>754-319-0073</span>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-4 col-md-4 d-flex align-items-center justify-content-center">
                                <div className="single-cta">
                                    <i className="bi bi-envelope-at"></i>
                                    <div className="cta-text">
                                        <h4>Mail us</h4>
                                        <span>coastroadschool@gmail.com</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
            <div className='footerBot'>
                <span className="cafe"><i className="bi bi-c-circle"></i> Copyright 2023 • Coast Road Driving School • Designed by <a href='https://github.com/Mishaker22'> Mishaker</a></span>
                
            </div>
        </Fragment>
    )
}
