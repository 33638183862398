import './Api.css';
import './App.css';
import React, { useEffect, Suspense} from 'react';
import { Inicio } from './components/Inicio';
import { NavBar } from './components/layout/NavBar';
import { Footer } from './components/layout/Footer';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import store from './store';
import { loadUser } from './actions/user_actions';
import { LoadingPage } from './components/hooks/LoadingPage';
import ProtectedRoutes from './routes/Protected_routes';

const Dashboard = React.lazy(() => import('./components/admin/dashboard'));
const ServiceList = React.lazy(() => import('./components/admin/serviceList'));
const Login = React.lazy(() => import('./components/users/login'));
const Register = React.lazy(() => import('./components/users/register'));
const Profile = React.lazy(() => import('./components/users/profile'));
const UpdateProfile = React.lazy(() => import('./components/users/updateProfile'));
const UpdatePassword = React.lazy(() => import('./components/users/updatePassword'));
const ForgotPassword = React.lazy(() => import('./components/users/forgotPassword'));
const ResetPassword = React.lazy(() => import('./components/users/resetPassword'));
const ContactUs = React.lazy(() => import('./components/contact_us'));
const NewService = React.lazy(() => import('./components/admin/service_new'));
const UpdateService = React.lazy(() => import('./components/admin/updateService'));
const SubcategorieNew = React.lazy(() => import('./components/admin/subcategorie_new'));
const UpdateProduct = React.lazy(() => import('./components/admin/updateProduct'));
const UserList = React.lazy(() => import('./components/admin/UserList'));
const UpdateUser = React.lazy(() => import('./components/admin/updateUser'));
const Requires = React.lazy(() => import('./components/requires'));
const Testiomonials = React.lazy(() => import('./components/reviews/testiomonials'));
const Reviews = React.lazy(() => import('./components/reviews/reviews'));
const ListReviews = React.lazy(() => import('./components/admin/listReviews'));
const ListRoleUsers = React.lazy(() => import('./components/admin/listRoleUsers'));
const ListRoleEmployees = React.lazy(() => import('./components/admin/listRoleEmployees'));
const ListReviewsPositivas = React.lazy(() => import('./components/admin/listReviewsPositivas'));
const ListReviewsNegativas = React.lazy(() => import('./components/admin/listReviewsNegativas'));
const ServiceDetails = React.lazy(()=> import('./components/services/service_details'));

const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
    
  }, [pathname]);

  return null;
};

function App() {

  useEffect(() => {
    const userToken = localStorage.getItem('userToken');
    console.log('Valor de userToken:', userToken);
    if (userToken) {
      store.dispatch(loadUser());
    }
  }, [])
  return (
    <Router>
      <ScrollToTop />
      <div className="App">
        <NavBar></NavBar>
        <Suspense fallback={<LoadingPage></LoadingPage>}>
          <Routes>
            <Route path='/' element={<Inicio></Inicio>}></Route>
            <Route path='/home' element={<Inicio></Inicio>}></Route>
            <Route path='/service/:id' element={<ServiceDetails />}></Route>
            <Route path='/contact_us' element={<ContactUs></ContactUs>}></Route>
            <Route path='/login' element={<Login></Login>}></Route>
            <Route path='/register' element={<Register></Register>}></Route>
            <Route path='/MyProfile' element={<Profile></Profile>}></Route>
            <Route path='/MyProfile/update' element={<UpdateProfile></UpdateProfile>}></Route>
            <Route path='/MyProfile/updatePassword' element={<UpdatePassword></UpdatePassword>}></Route>
            <Route path='/forgotPassword' element={<ForgotPassword></ForgotPassword>}></Route>
            <Route path='/resetPassword/:token' element={<ResetPassword></ResetPassword>}></Route>
            <Route path='/#requires' element={<Requires></Requires>}></Route>
            <Route path='/#testimonials' element={<Testiomonials></Testiomonials>} ></Route>
            <Route path='/reviews' element={<Reviews></Reviews>} ></Route>

            {/*Rutas Protegida*/}
            <Route path='/admin/dashboard' element={<ProtectedRoutes isAdmin={true}><Dashboard></Dashboard></ProtectedRoutes>}></Route>
            <Route path='/admin/listServices' element={<ProtectedRoutes isAdmin={true}><ServiceList></ServiceList></ProtectedRoutes>}></Route>
            <Route path='/admin/newService' element={<ProtectedRoutes isAdmin={true}><NewService></NewService></ProtectedRoutes>}></Route>
            <Route path='/admin/updateService/:id' element={<ProtectedRoutes isAdmin={true}><UpdateService></UpdateService></ProtectedRoutes>}></Route>
            <Route path='/admin/createSubcategorie/:id' element={<ProtectedRoutes isAdmin={true}><SubcategorieNew></SubcategorieNew></ProtectedRoutes>}></Route>
            <Route path='/admin/updateProduct' element={<ProtectedRoutes isAdmin={true}><UpdateProduct></UpdateProduct></ProtectedRoutes>}></Route>
            <Route path='/admin/listUsers' element={<ProtectedRoutes isAdmin={true}><UserList></UserList></ProtectedRoutes>}></Route>
            <Route path='/admin/listRoleUsers' element={<ProtectedRoutes isAdmin={true}><ListRoleUsers></ListRoleUsers></ProtectedRoutes>}></Route>
            <Route path='/admin/listRoleEmployees' element={<ProtectedRoutes isAdmin={true}><ListRoleEmployees></ListRoleEmployees></ProtectedRoutes>}></Route>
            <Route path='/admin/updateUser/:id' element={<ProtectedRoutes isAdmin={true}><UpdateUser></UpdateUser></ProtectedRoutes>}></Route>
            <Route path='/admin/listReviews' element={<ProtectedRoutes isAdmin={true}><ListReviews></ListReviews></ProtectedRoutes>}></Route>
            <Route path='/admin/listReviewsPositivas' element={<ProtectedRoutes isAdmin={true}><ListReviewsPositivas></ListReviewsPositivas></ProtectedRoutes>}></Route>
            <Route path='/admin/listReviewsNegativas' element={<ProtectedRoutes isAdmin={true}><ListReviewsNegativas></ListReviewsNegativas></ProtectedRoutes>}></Route>
          </Routes>
        </Suspense>
        <Footer></Footer>
      </div>
    </Router>
  );
}

export default App;
